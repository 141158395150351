<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row>
          <v-col cols="12" md="12" class="d-flex">
            <v-select type="text" filled label="Статус"
                      :items="statusTypeSelect"
                      v-model="status_type"
                      hide-details
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="status_type ? '' : 'req-star'"
                      color="grey"/>
          </v-col>
          <v-col cols="12" md="12" class="d-flex">
            <v-text-field type="text" filled label="Заголовок" v-model="theme"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="theme ? '' : 'req-star'"
                          full-width
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="12">
            <v-textarea type="text" filled label="Текст" v-model="body"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="body ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click.stop="crud_item" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import {
  CREATE_SITE_NOTIFY,
  REMOVE_SITE_NOTIFY,
  UPDATE_SITE_NOTIFY
} from "@/store/actions/personalSite/site";
import {statusTypeSelect} from "@/utils/icons";

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'notification_modal_delete'

export default {
  name: "HWP_ModalNotification",
  mixins: [ModalComponentMixin],
  data() {
    return {
      statusTypeSelect,
      theme: this.item.title,
      body: this.item.body,
      status_type: this.item.status_type || null,
      file: null,
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.theme = this.item.title
      this.body = this.item.body
      this.status_type = this.item.status_type || null

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення оповіщення ${this.theme}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Статус, Заголовок та Текст - мають бути заповненіі',
          color: 'error lighten-1'
        })
        return
      }

      const payload = {
        title: this.theme,
        body: this.body,
        status_type: this.status_type,
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_SITE_NOTIFY, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        payload['id'] = this.itemId

        this.$store.dispatch(UPDATE_SITE_NOTIFY, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.theme = payload.title
              this.body = payload.body
              this.status_type = payload.status_type || null
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_SITE_NOTIFY, this.itemId)
                    .then(ok => {
                      if (ok) {
                        this.closeModal()
                      }
                    })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  },
}
</script>

<style scoped lang="scss">
  .site-section {
    max-width: 1400px;
    margin: 0 auto;
    padding: 60px 10px;
  }
</style>